import pulse from '@finn-no/pulse-sdk';
/**
 *
 * @param {MouseEvent} event
 */
function handleClick(event) {
  const button = event.currentTarget;

  const proxyUrl = button
    .closest(['[data-proxy-url]'])
    .getAttribute('data-proxy-url');

  const csrfToken = button.getAttribute('data-csrf-token');

  button.classList.add('button--in-progress');

  const method =
    button.getAttribute('data-controller') === 'follow-company'
      ? 'POST'
      : 'DELETE';

  fetch(proxyUrl, {
    method,
    headers: {
      'csrf-token': csrfToken,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      const buttonParent = button.parentElement;

      // Update subscriber count
      const numberOfSubscribers = data.messages.subscribers;
      buttonParent.querySelector(
        'p'
      ).innerText = `${numberOfSubscribers} følger dette firmaet`;

      // Toggle hidden attribute of subscribe/unsubscribe buttons
      buttonParent
        .querySelectorAll('button')
        // eslint-disable-next-line no-param-reassign,no-return-assign
        .forEach((b) => (b.hidden = !b.hidden));

      const trackPulse =
        button.getAttribute('data-controller') === 'follow-company'
          ? {
              type: 'Click',
              intent: 'Follow',
              name: 'Follow company',
              object: {
                id: 'follow-company',
                type: 'UIElement',
                pageType: 'Link',
                hasExtendedProfile: true,
              },
              target: {
                id: 'follow-company',
                type: 'SavedList',
                name: 'Company profile list',
              },
            }
          : {
              type: 'Click',
              intent: 'Unfollow',
              name: 'Unfollow company',
              object: {
                id: 'unfollow-company',
                type: 'UIElement',
                pageType: 'Link',
                hasExtendedProfile: true,
              },
              target: {
                id: 'unfollow-company',
                type: 'SavedList',
                name: 'Company profile list',
              },
            };

      pulse.trackEvent(trackPulse);
    })
    .finally(() => button.classList.remove('button--in-progress'));
}

document.addEventListener('DOMContentLoaded', () => {
  const subscribe = document.querySelector('[data-controller=follow-company]');
  const unsubscribe = document.querySelector(
    '[data-controller=unfollow-company]'
  );

  // eslint-disable-next-line no-unused-expressions
  subscribe && subscribe.addEventListener('click', handleClick);
  // eslint-disable-next-line no-unused-expressions
  unsubscribe && unsubscribe.addEventListener('click', handleClick);
});
